import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaLinkButtonElement = class extends PolymerElement {
  static get is() {
    return 'jha-link-button';
  }

  static get properties() {
    return {
      href: String,
      rel: String,
      target: String,
      disabled: Boolean,
    };
  }

  onLinkClicked_(evt) {
    if (this.disabled) {
      evt.preventDefault();
    }
  }

  static get template() {
    return html`
    <style>
      :host {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        text-align: var(--jha-link-button-text-align, center);
        contain: layout;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: var(--jha-link-button-white-space, nowrap);
        background-color: var(--jha-button-background, var(--jha-color-primary));
        text-transform: none;
        font-size: var(--jha-button-text-size, 14px);
        line-height: var(--jha-button-line-height, 34px);
        transition: background-color ease 0.2s;
        color: var(--jha-button-text, var(--jha-text-white));
        font-weight: var(--jha-button-font-weight, 500);
        border-radius: var(--jha-button-border-radius, 8px);
      }

      a {
        display: block;
        text-decoration: none;
        padding: var(--jha-button-padding-vertical, 0px) var(--jha-button-padding-horizontal, 18px);
        border-radius: var(--jha-button-border-radius, 8px);
        color: inherit;
      }

      a:focus:not(:focus-visible) {
        outline: 0;
      }

      a:hover,
      a:focus-visible {
        text-decoration: none;
        color: var(--jha-button-text, var(--jha-text-white));
      }

      :host(:active) {
        background-color: var(--jha-button-background-active, var(--jha-color-primary));
      }

      :host([disabled]),
      :host([disabled]) a {
        cursor: default;
      }

      :host([disabled]) {
        opacity: var(--jha-button-disabled-opacity, 0.3);
      }

      :host ::slotted(.icon) {
        position: relative;
        top: -2px;
      }

      :host([flex]) a {
        display: flex;
        align-items: var(--jha-link-button-align-items, center);
        justify-content: var(--jha-link-button-justify-content, flex-start);
        text-align: left;
      }

      :host([outline]) {
        background-color: transparent;
        border-color: var(--jha-button-outline-border, var(--jha-color-primary));
        transition: background-color ease 0.2s;
      }

      :host([outline]) a {
        color: var(--jha-button-outline-text, var(--jha-color-primary));
      }

      :host([outline]:hover) {
        background-color: rgba(153, 153, 153, 0.12);
      }

      :host([outline]:active) {
        background-color: rgba(153, 153, 153, 0.4);
      }

      :host([outline]) a:hover {
        color: var(--jha-button-outline-text, var(--jha-color-primary));
        background-color: transparent;
      }

      :host([outline-primary]) {
        background-color: transparent;
        border-color: var(--jha-button-primary-background, var(--jha-color-primary));
      }

      :host([outline-primary]) a {
        color: var(--jha-button-primary-background, var(--jha-color-primary));
        background-color: transparent;
      }

      :host([outline-primary]:hover) {
        background-color: var(--jha-button-outline-primary-background-hover, rgba(153, 153, 153, 0.12));
      }

      :host([outline-primary]:hover) a {
        background-color: transparent;
      }

      :host([outline-primary]:active) {
        background-color: var(--jha-button-primary-background, var(--jha-color-primary));
      }

      :host([outline-primary]:active) a {
        color: var(--jha-button-text, var(--jha-text-white));
        background-color: transparent;
      }

      :host([outline-success]) {
        background-color: transparent;
        border-color: var(--jha-button-success-background, var(--jha-color-success));
      }

      :host([outline-success]) a {
        color: var(--jha-button-success-background, var(--jha-color-success));
        background-color: transparent;
      }

      :host([outline-success]:hover) {
        background-color: var(--jha-button-outline-success-background-hover, rgba(153, 153, 153, 0.12));
      }

      :host([outline-success]:hover) a {
        background-color: transparent;
      }

      :host([outline-success]:active) {
        background-color: var(--jha-button-success-background, var(--jha-color-success));
      }

      :host([outline-success]:active) a {
        color: var(--jha-button-text, var(--jha-text-white));
        background-color: transparent;
      }

      :host([outline-danger]) {
        background-color: transparent;
        border-color: var(--jha-button-danger-background, var(--jha-color-danger));
      }

      :host([outline-danger]) a {
        color: var(--jha-button-danger-background, var(--jha-color-danger));
        background-color: transparent;
      }

      :host([outline-danger]:hover) {
        background-color: var(--jha-button-outline-danger-background-hover, rgba(153, 153, 153, 0.12));
      }

      :host([outline-danger]:hover) a {
        background-color: transparent;
      }

      :host([outline-danger]:active) {
        background-color: var(--jha-button-danger-background, var(--jha-color-danger));
      }

      :host([outline-danger]:active) a {
        color: var(--jha-button-text, var(--jha-text-white));
        background-color: transparent;
      }

      :host([icon]) {
        background-color: transparent;
      }

      :host([icon]):hover,
      :host([icon]):focus {
        background-color: transparent;
        box-shadow: none;
      }

      :host([icon]) a:hover {
        background-color: transparent;
      }

      :host([link]) {
        color: var(--jha-button-link-text, var(--jha-color-primary, #006ee4));
        font-weight: var(--jha-button-link-font-weight, 500);
        line-height: var(--jha-button-line-height, 34px);
        background-color: transparent;
      }

      :host([link]) a {
        padding: var(--jha-button-link-padding-vertical, 0px) var(--jha-button-link-padding-horizontal, 18px);
      }

      :host([link]) a:hover ,
      :host([link]) a:focus-visible {
        text-decoration: var(--jha-button-link-text-decoration, none);
        color: var(--jha-button-link-text-hover, var(--jha-color-primary, #006ee4));
        background-color: var(--jha-button-link-background-hover, rgba(0, 110, 228, .10));
      }

      :host([link][disabled]) a:hover,
      :host([link][disabled]) a:focus-visible,
      :host([link][disabled]) a:active {
        background-color: transparent;
      }

      :host([link]) a:active {
        background-color: var(--jha-button-link-background-active, rgba(0, 110, 228, .20));
      }

      :host([link][footer]) {
        margin-left: var(--jha-button-link-footer-margin-left, -18px);
      }

      :host([primary]) {
        background-color: var(--jha-button-primary-background, var(--jha-color-primary));
      }

      :host([primary]) a:hover {
        background-color: transparent;
      }

      :host([neutral]) {
        background-color: var(--jha-button-neutral-background, transparent);
      }

      :host([neutral]) a:hover {
        background-color: transparent;
      }

      :host([success]) {
        background-color: var(--jha-button-success-background, var(--jha-color-success));
      }

      :host([success]) a:hover {
        background-color: transparent;
      }

      :host([danger]) {
        background-color: var(--jha-button-danger-background, var(--jha-color-danger));
      }

      :host([danger]) a:hover {
        background-color: transparent;
      }

      :host([toggle]) {
        background-color: transparent;
      }

      :host([toggle]) a {
        color: var(--jha-button-toggle-text, var(--jha-color-neutral));
        font-size: var(--jha-button-toggle-text-size, 13px);
        font-weight: var(--jha-link-button-toggle-font-weight, 600);
        text-transform: var(--jha-link-button-toggle-text-transform, uppercase);
      }

      :host([toggle]) a:hover {
        color: var(--jha-button-primary-background, var(--jha-color-primary));
        background-color: transparent;
      }
      :host([small]) a {
        font-size: calc(var(--jha-button-text-size, 14px) - 1);
        line-height: var(--jha-button-line-height-small, 28px);
      }

      :host([large]) a {
        line-height: var(--jha-button-line-height-large, 48px);
        padding-left: calc(var(--jha-button-padding-horizontal, 18px) * 2);
        padding-right: calc(var(--jha-button-padding-horizontal, 18px) * 2);
      }

      :host([wide]) a {
        padding-left: calc(var(--jha-button-padding-horizontal, 18px) * 2);
        padding-right: calc(var(--jha-button-padding-horizontal, 18px) * 2);
      }

      :host([block]) {
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }

      :host([block]:last-of-type) {
        margin-bottom: 0;
      }

      :host([default]) {
        color: var(--jha-button-default-text, var(--jha-text-base, #6b757b));
        background-color: var(--jha-button-default-background, transparent);
        border-color: var(--jha-button-default-border, var(--jha-border-color, #e4e7ea));
        font-weight: var(--jha-button-default-font-weight, 400);
        font-size: var(--jha-button-default-font-size, 14px);
        transition: background-color ease 0.2s;
      }

      :host([default]) a:hover {
        background-color: var(--jha-button-default-background-hover, rgba(153, 153, 153, 0.12));
        color: var(--jha-button-default-text-color-hover, var(--jha-text-base, #6b757b));
      }

      :host([default]) a:active {
        background-color: var(--jha-button-default-background-active, rgba(153, 153, 153, 0.4));
        color: var(--jha-button-default-text-color-hover, var(--jha-text-base, #6b757b));
      }
    </style>
    <a href$="[[href]]" rel$="[[rel]]" target$="[[target]]" on-click="onLinkClicked_">
      <slot></slot>
    </a>
  `;
  }
};

customElements.define(window.JhaLinkButtonElement.is, window.JhaLinkButtonElement);
export default window.JhaLinkButtonElement;
